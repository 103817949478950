import { $ } from './../helpers/query.js';
import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';
import { isMacintosh } from './../helpers/detect-device';

export default () => {

  $('.home-hero').each(section => {

    if (isMacintosh()) {
      $('.home-hero__bold', section).addClass('home-hero__bold--mac')
    }

    const titleCarouselEl = section.querySelector('.home-hero__bold');
    const titleCarousel = new Swiper(titleCarouselEl, {
      direction: 'vertical',
      loop: true,
      allowTouchMove: false,
      speed: 600,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
    });

    $('.home-hero__img', section).each(carouselEl => {
      const carousel = new Swiper(carouselEl, {
        effect: 'fade',
        allowTouchMove: false,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      })
    })

    const logosCarouselEl = section.querySelector('.home-hero__logos .swiper-container');
    const logosCarousel = new Swiper(logosCarouselEl, {
      direction: 'vertical',
      loop: true,
      allowTouchMove: false,
      speed: 0,
      autoplay: {
        delay: 5700,
        disableOnInteraction: false,
        reverseDirection: true
      },
      on: {
        slideChangeTransitionEnd() {
          const swiper = this;
          setLogosTranslate(swiper);
        },
      }
    })

    function setLogosTranslate(swiper) {
      swiper.slides.forEach((slide) => {

        if (slide.matches('.swiper-slide-active')) {

          let timeout = 4500;
          $('.home-hero__logo', slide).each((logo, i) => {
            if ($(logo).getCss('display') != 'none') {
              timeout -= 0.2 * 1000;
            }
          })

          $('.home-hero__logo', slide).each((logo, i) => {

            gsap.to(logo, {
              y: 0,
              delay: 0.2 * i,
              duration: 1,
              ease: "power2.out",
              onComplete: () => {
                setTimeout(() => {
                  gsap.to(logo, {
                    y: 54,
                    duration: 0.6,
                    ease: "power1.in",
                    onComplete: () => {
                      gsap.set(logo, {
                        y: -54
                      })
                    }
                  })
                }, timeout)
              }
            })

          })
        }

      });
    }

  })

  $('.about').each(section => {

    const carouselEl = section.querySelector('.about__carousel .swiper-container');
    const carousel = new Swiper(carouselEl, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      speed: 800,
      navigation: {
        nextEl: section.querySelector('.about__next'),
        prevEl: section.querySelector('.about__prev'),
      },
      pagination: {
        el: section.querySelector('.about__scrollbar'),
        type: 'progressbar',
        progressbarFillClass: 'about__scrollbar-fill'
      },
    });

  })

  $('.home-portfolio').each(section => {

    const carouselEl = section.querySelector('.home-portfolio__logos .swiper-container');
    const carousel = new Swiper(carouselEl, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      speed: 500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: section.querySelector('.home-portfolio__counters'),
        type: 'fraction',
      },
    })

  })

  $('.reviews').each(section => {

    if (section.querySelector('.review-author__photo')) {
      $('.reviews__nav, .reviews__pagination', section).each(elem => {
        $(elem).css({
          'margin-bottom': () => {
            const startMargin = $(elem).getCss('margin-bottom');
            const photoHeight = $('.review-author__photo', section).height();
            return startMargin + photoHeight;
          }
        })
      })
    }

    const carouselEl = section.querySelector('.reviews__carousel');
    $('.reviews__total-number').setHTML(`0${$('.review', section).length}`);

    const carousel = new Swiper(carouselEl, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      // autoHeight: true,
      loop: true,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: section.querySelector('.reviews__next'),
        prevEl: section.querySelector('.reviews__prev'),
      },
      pagination: {
        el: section.querySelector('.reviews__pagination'),
        type: 'bullets',
        bulletClass: 'reviews__bullet',
        bulletActiveClass: 'reviews__bullet--active',
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="${className}">
              <svg class="reviews__animated-border" width="24px" height="24px" viewbox="0 0 24 24" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11"/>
              </svg>
          </span>`;
        }
      }
    })

    const setActiveIndex = () => {
      $('.reviews__current-number').setHTML(`0${carousel.realIndex + 1}`);
    };

    setActiveIndex()
    carousel.on('activeIndexChange', setActiveIndex);

  })

}

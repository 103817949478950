export default (selector) => {
	const anchors = document.querySelectorAll(selector);
	
	anchors.forEach((anchor) => {
		anchor.addEventListener("click", (e) => {
			e.preventDefault();
			
			const blockID = anchor.dataset.id || anchor.hash.slice(1);
			const elemToScroll = document.getElementById(blockID);
			const headerHeight = document.querySelector(".header").clientHeight;
			if (elemToScroll) {
				const scrollToPosition = elemToScroll.getBoundingClientRect().top + window.pageYOffset - headerHeight;

				window.scrollTo({
					top: scrollToPosition,
					behavior: "smooth",
				});
				anchors.forEach(a =>{
					a.classList.remove('active');
					
				})
				anchors.forEach(a =>{
					if (a == anchor){
						a.classList.add('active');
					}
				})
				
			}
		});
	});
};

import homePage from './pages/home-page';
import communityPage from './pages/community-page';
import teamPage from './pages/team-page';
import portfolioPage from './pages/portfolio-page';
import contactPage from './pages/contact-page';
import textPage from './pages/text-page';

export default function() {
  const pageName = document.body.getAttribute('data-page-name');
  if (!pageName) return;

  const pageList = {
    homePage,
    communityPage,
    teamPage,
    portfolioPage,
    contactPage,
    textPage
  };

  if (pageName && pageList[pageName]) pageList[pageName]();
}

import { $ } from './../helpers/query.js';
import { gsap } from 'gsap';

export default class {
  constructor() {
    this.header = $('#header');
    this.heroSel = '#hero';
    this.burger = $('#open-menu');
    this.burgerCaption = this.burger.find('.burger__caption');
    this.menu = $('#menu');
    this.menuList = this.menu.find('.menu__list');
    this.menuLinks = $('.header__logo, .menu__link');
    this.menuSocials = this.menu.find('.menu__socials');
    this.scrolledClass = 'header--scroll';
    this.scrollDownClass = 'header--scroll-down';
    this.openedClass = 'header--open';
    this.menuOpenClass = 'menu--open';
    this.menuAnimClass = 'menu--animated';
    this.currentScroll = window.pageYOffset;
    this.OPEN_DURATION = 800;
  }

  init() {
    this.toggleScrolledState();
    this.toggleScrollDownState();
    this.toggleMenu();
    this.closeMenuOnLinkClick();

    window.addEventListener('scroll', () => {
      this.toggleScrolledState();
      this.toggleScrollDownState();
      this.currentScroll = Math.max(window.pageYOffset, 0);
    })
  }

  toggleScrolledState() {
    if (window.pageYOffset >= 50) {
      this.setScrolledState();
    } else {
      this.removeScrolledState();
    }
  }

  closeMenuOnLinkClick() {
    this.menuLinks.on('click', (e, link) => {
      if (this.menu.is(`.${this.menuOpenClass}`)) {
        this.closingMenu();
      }
    })
  }

  setScrolledState() {
    this.header.addClass(this.scrolledClass);
  }

  removeScrolledState() {
    this.header.removeClass(this.scrolledClass);
  }

  toggleScrollDownState() {
    if (Math.max(window.pageYOffset, 0) > this.currentScroll) {
      this.setScrollDownState()
    } else {
      this.removeScrollDownState()
    }
  }

  setScrollDownState() {
    this.header.addClass(this.scrollDownClass);
  }

  removeScrollDownState() {
    this.header.removeClass(this.scrollDownClass);
  }

  toggleMenu() {
    this.burger.on('click', (e, elem) => {
      e.preventDefault();
      if (!this.menu.is(`.${this.menuOpenClass}`)) {
        this.openingMenu()
      } else {
        this.closingMenu()
      }
    })
  }

  toggleBurgerCaption() {
    const text = this.burgerCaption.html();
    this.burgerCaption.setHTML(this.burgerCaption.getAttr('data-burger-caption'));
    this.burgerCaption.setAttr('data-burger-caption', text);
  }

  openingMenu() {
    document.body.classList.add('body--no-scroll');
    this.menu.addClass(this.menuOpenClass);
    setTimeout(() => {
      this.removeScrollDownState()
      this.header.addClass(this.openedClass);
      this.toggleBurgerCaption();
      this.menu.addClass(this.menuAnimClass);
      gsap.set(this.menuList.elem, {
        opacity: 1,
        delay: this.OPEN_DURATION / 2000,
      });
      gsap.to(this.menuList.elem, {
        x: 0,
        duration: 1,
        delay: this.OPEN_DURATION / 2000,
        ease: "power2.out",
      });
      gsap.set(this.menuSocials.elem, {
        opacity: 1,
        delay: this.OPEN_DURATION / 2000,
      });
    }, 10);
  }

  closingMenu() {
    this.menu.removeClass(this.menuAnimClass);
    gsap.set(this.menuList.elem, {
      opacity: 0,
      x: 200,
      delay: this.OPEN_DURATION / 3000,
    });
    gsap.set(this.menuSocials.elem, {
      opacity: 0,
      delay: this.OPEN_DURATION / 3000,
    });
    setTimeout(() => {
      this.header.removeClass(this.openedClass);
      this.toggleBurgerCaption();
      this.menu.removeClass(this.menuOpenClass);
      document.body.classList.remove('body--no-scroll');
    }, 800);
  }

}

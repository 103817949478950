import Isotope from 'isotope-layout/js/isotope';
import { $ } from './../helpers/query.js';
import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';

export default () => {
  $('.portfolio-hero').each(section => {
  $('.portfolio-carousel__img', section).each(carouselEl => {
    const carousel = new Swiper(carouselEl, {
      effect: 'fade',
      allowTouchMove: false,
      speed: 2000,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
    })
  })
  })

  const elem = document.querySelector('.portfolio__cards-wrapper');
  const iso = new Isotope(elem, {
    itemSelector: '.portfolio__card',

  });

  let filterBtn = document.querySelectorAll('.portfolio-filter .portfolio-filter-btn');
  for (let i = 0; i < filterBtn.length; i++) {
      filterBtn[i].onclick = function (click) {
          let filterData = event.target.getAttribute('data-filter');
          // Применяем фильтрацию элементов в Isotope
          iso.arrange({
              filter: filterData
          });
      };
  }
}

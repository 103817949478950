import { $ } from './helpers/query';
import { scrollTo } from './helpers/scroll-to';
import Header from './components/Header';
import simpleParallax from 'simple-parallax-js';

export default () => {
  scrollTo()

  const header = new Header();
  header.init();

  $('.parallax-form').each(form => {
    new simpleParallax(form, {
      scale: 2,
      overflow: true,
    });
  })

  $('.color-bg').each(section => {

    let topColor, bottomColor;

    if ($(section).prevSibling()) {
      topColor = $($(section).prevSibling()).getCss('background-color');
    } else {
      topColor = 'rgba(0, 0, 0, 0)';
    }
    if ($(section).nextSibling()) {
      bottomColor = $($(section).nextSibling()).getCss('background-color');
    } else {
      bottomColor = $('.footer').getCss('background-color');
    }

    $(section).css({
      'background-image': `linear-gradient(to bottom, ${topColor} 0%, ${topColor} 50%, ${bottomColor} 50%, ${bottomColor} 100%)`
    });

  })

  $('.fadeIn-elem').scrollTrigger({
    triggerSel: '.fadeIn-container',
    triggerPoint: '80%',
    callback: elem => {
      $(elem).removeClass('fadeIn-start');
      const duration = parseFloat($(elem).getCss('transition-duration'));
      const delay = parseFloat($(elem).getCss('transition-delay'));
      setTimeout(() => {
        $(elem).removeClass('fadeIn-elem');
        for (let i = 1; i < 20; i++) {
          $(elem).removeClass(`fadeIn-order-${i}`);
          $(elem).removeClass(`fadeIn-order-md-${i}`);
          $(elem).removeClass(`fadeIn-order-sm-${i}`);
        }
      }, (duration + delay) * 1000)
    }
  });
}

import { $ } from './../helpers/query.js';
import Swiper from 'swiper/bundle';
import { gsap } from 'gsap';
import simpleParallax from 'simple-parallax-js';

export default () => {

  $('.comm-hero').each(section => {
  $('.comm-hero__photo', section).each(carouselEl => {
    const carousel = new Swiper(carouselEl, {
      effect: 'fade',
      allowTouchMove: false,
      speed: 2000,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
    })
  })
  })

  $('.screenshot__img').each(img => {
    new simpleParallax(img, {
      scale: 1.5,
      overflow: true,
    });
  });

  $('.screenshot__img').each(item => {
    if (item.matches('video')) {
      item.play()
    }
  });

  $('.screenshot__img').each(img => {
    new simpleParallax(img, {
      scale: 1.5,
      overflow: true,
    });
  })

  $('.mentors__carousel').each(carouselEl => {

    let carousel;
    toggleCarousel();
    window.addEventListener('resize', toggleCarousel);

    function toggleCarousel() {
      if (document.documentElement.clientWidth < 640 && !carousel) {
        init()
      } else if (document.documentElement.clientWidth >= 640 && carousel) {
        carousel.destroy();
        carousel = null;
      }

      function init() {
        carousel = new Swiper(carouselEl, {
          effect: 'fade',
          wrapperClass: 'mentors__grid',
          slideClass: 'mentors__cell',
          allowTouchMove: false,
          pagination: {
            el: carouselEl.querySelector('.mentors__pagination'),
            type: 'fraction'
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          breakpoints: {
            0: {
              allowTouchMove: true
            },

            640: {
              allowTouchMove: false
            }
          }
        });
      }
    }

  })

  $('.benefits__container').each(carouselEl => {

    let carousel;
    toggleCarousel();
    window.addEventListener('resize', toggleCarousel);

    function toggleCarousel() {
      if (document.documentElement.clientWidth < 640 && !carousel) {
        init()
      } else if (document.documentElement.clientWidth >= 640 && carousel) {
        carousel.destroy();
        carousel = null;
      }

      function init() {
        carousel = new Swiper(carouselEl, {
          loop: true,
          speed: 500,
          wrapperClass: 'benefits__carousel-wrapper',
          slideClass: 'benefits__line',
          allowTouchMove: false,
          autoHeight: true,
          autoplay: {
            delay: 7000,
            disableOnInteraction: false
          },
          navigation: {
            nextEl: carouselEl.querySelector('.benefits__next'),
            prevEl: carouselEl.querySelector('.benefits__prev'),
          }
        });
      }
    }

  })


};
